import skf from './OrtaklarData/skf.png';
import fag from './OrtaklarData/fag.png'
import fkc from './OrtaklarData/fkc.png';

export const OrtakData = [
    {
        title: "SKF",
        image: skf,
    },
    {
        title: "FAG",
        image: fag,
    },
    {
        title: "FKC",
        image: fkc,
    },
];