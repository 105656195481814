export const oneCikanUrunler = [
    {
        urun_id: 1,
        urun_ad: "UCP 204",
        urun_aciklama: "YATKLI RULMAN DÖKÜM",
        urun_fiyat: null,
    },
    {
        urun_id: 2,
        urun_ad: "UCP 205",
        urun_aciklama: "YATKLI RULMAN DÖKÜM",
        urun_fiyat: null,
    },
    {
        urun_id: 3,
        urun_ad: "UCP 206",
        urun_aciklama: "YATKLI RULMAN DÖKÜM",
        urun_fiyat: null,
    },
    {
        urun_id: 4,
        urun_ad: "UCP 207",
        urun_aciklama: "YATKLI RULMAN DÖKÜM",
        urun_fiyat: null,
    },
]