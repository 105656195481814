import foto2 from './SliderData/foto2.jpeg'
import foto3 from './SliderData/foto3.jpeg'
import foto4 from './SliderData/foto4.jpeg'

export const SliderData = [
    {
        type: "image",
        image: foto2,
        url: '/',
    },
    {
        type: "image",
        image: foto3,
        url: '/',
    },
    {
        type: "image",
        image: foto4,
        url: "/",
    },
];