export const IletisimData = [
    {
        title: "Adres",
        text: "İosb Tormak Sanayi Sitesi Sosyal Tesis A Blok No:3 Zemin Kat İkitelli/İstanbul",
        which_icon: "1",
    },
    {
        title: "Telefon Numarası",
        text: "+90 537 622 84 63,+90 212 485 90 76,+90 212 485 90 77",
        which_icon: "2",
    },
    {
        title: "Mail",
        text: "celiktepe-rulman-fkc@hotmail.com",
        which_icon: "3",
    },
];