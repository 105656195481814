export const menuItemsData = [
    {
        title: 'ANA SAYFA',
        url: '/anasayfa',

    },
    {
        title: 'KURUMSAL',
        url: '/kurumsal',
    },
    {
        title: 'ÇALIŞMA ORTAKLARI',
        url: '/calisma-ortaklari',
    },
    {
        title: 'ÜRÜNLER',
        url: '/',
    },
    {
        title: 'İLETİŞİM',
        url: '/iletisim',
    },
];