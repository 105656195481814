import Slider from "../../Components/Slider";
//import Vitrin from "../../Components/Vitrin";
import Blog from "../../Components/Blog";

const AnaSayfaMainSection = () => {
    return (
        <div>
            <Slider />
            {/*<Vitrin />*/}
            <Blog />
        </div>
    )
}

export default AnaSayfaMainSection;